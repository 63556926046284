import { createAction } from 'redux-actions'

import {
  START_FETCHING_MONITOR,
  STOP_FETCHING_MONITOR,
  REQUEST_BACKTESTING_TABLE,
  RECEIVE_BACKTESTING_TABLE,
  REQUEST_BACKTESTING_CHART,
  RECEIVE_BACKTESTING_CHART,
  REQUEST_BACKTESTING_GRID_STATE_CHANGE,
  RECEIVE_BACKTESTING_GRID_STATE_CHANGE,
  RESET_BACKTESTING,
  REQUEST_BACKTESTING,
  REQUEST_BACKTESTING_AVAILABLE_FOLDS,
  RECEIVE_BACKTESTING_AVAILABLE_FOLDS,

  REQUEST_LIVE_MONITORING,
  RESET_LIVE_MONITORING,
  REQUEST_LIVE_MONITORING_TABLE,
  RECEIVE_LIVE_MONITORING_TABLE,
  REQUEST_LIVE_MONITORING_CHART,
  RECEIVE_LIVE_MONITORING_CHART,
  REQUEST_LIVE_MONITORING_FORECAST_HORIZONS,
  RECEIVE_LIVE_MONITORING_FORECAST_HORIZONS,
  REQUEST_LIVE_MONITORING_GRID_STATE_CHANGE,
  RECEIVE_LIVE_MONITORING_GRID_STATE_CHANGE,

  REQUEST_BASELINE_COMPARISON,
  RESET_BASELINE_COMPARISON,
  REQUEST_BASELINE_COMPARISON_TABLE,
  RECEIVE_BASELINE_COMPARISON_TABLE,
  REQUEST_BASELINE_COMPARISON_CHART,
  RECEIVE_BASELINE_COMPARISON_CHART,
  REQUEST_BASELINE_COMPARISON_GRID_STATE_CHANGE,
  RECEIVE_BASELINE_COMPARISON_GRID_STATE_CHANGE,
  REQUEST_BASELINE_COMPARISON_FORECAST_HORIZONS,
  RECEIVE_BASELINE_COMPARISON_FORECAST_HORIZONS,
} from './monitor.action-types'

import {
  RequestBacktestingTableActionPayload,
  RequestBacktestingChartActionPayload,
  RequestBacktestingGridStateChangePayload,
  RequestLiveMonitoringGridStateChangePayload,
  RequestLiveMonitoringTableActionPayload,
  RequestLiveMonitoringChartActionPayload,
  RequestBacktestingActionPayload,
  RequestLiveMonitoringActionPayload,
  RequestBaselineComparisonActionPayload,
  RequestBaselineComparisonGridStateChangePayload,
  RequestBaselineComparisonChartActionPayload,
  RequestBaselineComparisonTableActionPayload,
} from './monitor.types'

export const startFetchingMonitorAction = createAction<string>(START_FETCHING_MONITOR)
export const stopFetchingMonitorAction = createAction(STOP_FETCHING_MONITOR)

export const requestBacktestingTableAction = createAction<RequestBacktestingTableActionPayload>(REQUEST_BACKTESTING_TABLE)
export const receiveBacktestingTableAction = createAction(RECEIVE_BACKTESTING_TABLE)

export const requestBacktestingChartAction = createAction<RequestBacktestingChartActionPayload>(REQUEST_BACKTESTING_CHART)
export const receiveBacktestingChartAction = createAction(RECEIVE_BACKTESTING_CHART)

export const requestBacktestingGridStateChangeAction = createAction<RequestBacktestingGridStateChangePayload>(REQUEST_BACKTESTING_GRID_STATE_CHANGE)
export const receiveBacktestingGridStateChangeAction = createAction(RECEIVE_BACKTESTING_GRID_STATE_CHANGE)

export const resetBacktestingAction = createAction(RESET_BACKTESTING)
export const requestBacktestingAction = createAction<RequestBacktestingActionPayload>(REQUEST_BACKTESTING)

export const requestBacktestingAvailableFoldsAction = createAction<Hera.ListBacktestFoldsApiRequest>(REQUEST_BACKTESTING_AVAILABLE_FOLDS)
export const receiveBacktestingAvailableFoldsAction = createAction(RECEIVE_BACKTESTING_AVAILABLE_FOLDS)

export const requestLiveMonitoringAction = createAction<RequestLiveMonitoringActionPayload>(REQUEST_LIVE_MONITORING)
export const resetLiveMonitoringAction = createAction(RESET_LIVE_MONITORING)

export const requestLiveMonitoringTableAction = createAction<RequestLiveMonitoringTableActionPayload>(REQUEST_LIVE_MONITORING_TABLE)
export const receiveLiveMonitoringTableAction = createAction(RECEIVE_LIVE_MONITORING_TABLE)

export const requestLiveMonitoringChartAction = createAction<RequestLiveMonitoringChartActionPayload>(REQUEST_LIVE_MONITORING_CHART)
export const receiveLiveMonitoringChartAction = createAction(RECEIVE_LIVE_MONITORING_CHART)

export const requestLiveMonitoringForecastHorizonsAction = createAction<Hera.ListForecastHorizonsApiRequest>(REQUEST_LIVE_MONITORING_FORECAST_HORIZONS)
export const receiveLiveMonitoringForecastHorizonsAction = createAction(RECEIVE_LIVE_MONITORING_FORECAST_HORIZONS)

export const requestLiveMonitoringGridStateChangeAction = createAction<RequestLiveMonitoringGridStateChangePayload>(REQUEST_LIVE_MONITORING_GRID_STATE_CHANGE)
export const receiveLiveMonitoringGridStateChangeAction = createAction(RECEIVE_LIVE_MONITORING_GRID_STATE_CHANGE)

export const requestBaselineComparisonAction = createAction<RequestBaselineComparisonActionPayload>(REQUEST_BASELINE_COMPARISON)
export const resetBaselineComparisonAction = createAction(RESET_BASELINE_COMPARISON)

export const requestBaselineComparisonTableAction = createAction<RequestBaselineComparisonTableActionPayload>(REQUEST_BASELINE_COMPARISON_TABLE)
export const receiveBaselineComparisonTableAction = createAction(RECEIVE_BASELINE_COMPARISON_TABLE)

export const requestBaselineComparisonChartAction = createAction<RequestBaselineComparisonChartActionPayload>(REQUEST_BASELINE_COMPARISON_CHART)
export const receiveBaselineComparisonChartAction = createAction(RECEIVE_BASELINE_COMPARISON_CHART)

export const requestBaselineComparisonGridStateChangeAction = createAction<RequestBaselineComparisonGridStateChangePayload>(REQUEST_BASELINE_COMPARISON_GRID_STATE_CHANGE)
export const receiveBaselineComparisonGridStateChangeAction = createAction(RECEIVE_BASELINE_COMPARISON_GRID_STATE_CHANGE)

export const requestBaselineComparisonForecastHorizonsAction = createAction<Hera.ListForecastHorizonsApiRequest>(REQUEST_BASELINE_COMPARISON_FORECAST_HORIZONS)
export const receiveBaselineComparisonForecastHorizonsAction = createAction(RECEIVE_BASELINE_COMPARISON_FORECAST_HORIZONS)
